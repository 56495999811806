/*-------- Calendar -------*/
.cal1 {
	.clndr .clndr-table {
		.header-days .header-day {
			border-left: 1px solid rgba(70, 127, 207, 0.1);
			border-top: 1px solid $border-color;
		}
		tr {
			.adjacent-month, .empty, .my-adjacent-month, .my-empty {
				border-left: 1px solid #79c2b2;
				border-top: 1px solid #79c2b2;
				background: $border-color;
			}
			.day {
				&.event, &.my-event {
					background: #f6f7fb;
				}
				&.event:hover, &.my-event:hover {
					background: #15e0fd;
				}
				border-left: 1px solid $border-color;
				border-top: 1px solid $border-color;
				&:last-child {
					border-right: 1px solid $border-color;
				}
				&:hover {
					background: #e6f2fe;
				}
			}
		}
	}
	font-size: $default-value-size * 14;
	border: 1px solid $border-color;
}
.fc-unthemed {
	.fc-content, .fc-divider, .fc-list-heading td, .fc-list-view, .fc-popover, .fc-row, tbody, td, th, thead {
		border-color: rgba(227, 237, 252, 1);
	}
}
.fc-event {
	border: 1px solid $border-color;
}
.fc-unthemed {
	.fc-divider, .fc-list-heading td, .fc-popover .fc-header {
		background: $border-color;
	}
}
.fc-toolbar:before, .login-content:after {
	content: "";
}
.fc-toolbar {
	.fc-state-active, .ui-state-active {
		background: #09acca;
	}
}
.fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right fc-state-disabled:focus {
	border: none !important;
	box-shadow: none !important;
}
.fc-unthemed .fc-list-item:hover td {
	background-color: $border-color;
}
.timeline-calendar {
	.fc-theme-standard td {
		height: 60px;
	}
	.fc .fc-toolbar-title {
		display: none;
	}
	.fc-scroller.fc-scroller-liquid-absolute {
		overflow: hidden !important;
	}
	.fc-col-header-cell {
		height: 39px;
		line-height: 37px;
	}
}
.fc .fc-non-business {
	background: rgba(245, 244, 251, 0);
}
.timeline-calendar .fc .fc-daygrid-day.fc-day-today {
	background-color: rgb(245, 244, 251);
}
/*-------- Calendar -------*/