/*---- Custom radio-----*/

.custom-radio {
	.custom-control-label::before {
		border-radius: 50%;
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
		}
		&:disabled:checked~.custom-control-label::before {
			background-color: rgba(0, 20, 142, 0.6);
		}
	}
}
/*---- Custom radio-----*/