/*-------- Date Picker -------*/
.ui-datepicker {
	background-color: $white;
	border: 1px solid $border-color;
	font-family: inherit;
	font-size: inherit;
	padding: 10px;
	margin: 7px 0 0;
	display: none;
	width: auto !important;
	z-index: 999 !important;
	border-radius:5px;
	box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
	background: $white !important;
	overflow: hidden;
	&.ui-widget-content{
		padding: 5px 10px 10px 10px !important;
	}
	.ui-datepicker-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		font-weight: $font-weight-semibold;
		font-size: $default-value-size * 12;
		text-transform: uppercase;
		color: $text-color;
		padding: 0 0 5px;
		letter-spacing: 1px;
		border: 0;
		background-color: transparent;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		background: $white !important;
		padding: 0.75em 0 !important;
		.ui-datepicker-next, .ui-datepicker-prev {
			text-indent: -99999px;
			color: $text-color;
			top: 10px;
		}
		.ui-datepicker-next::before, .ui-datepicker-prev::before {
			font-size: $default-value-size * 16;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			position: absolute;
			top: 4px;
			text-indent: 0;
		}
		.ui-datepicker-next {
			&:focus::before, &:hover::before {
				color: #343a40;
			}
		}
		.ui-datepicker-prev {
			&:focus::before, &:hover::before {
				color: #343a40;
			}
		}
		.ui-datepicker-next {
			order: 3;
			&:before {
				right: 5px;
				content: '\f105';
			}
		}
		.ui-datepicker-prev:before {
			left: 5px;
			content: '\f104';
		}
		.ui-datepicker-next-hover, .ui-datepicker-prev-hover {
			color: #3d4e67;
			cursor: pointer;
			top: 1px;
			border: 0;
			background-color: transparent;
		}
	}
	.ui-datepicker-title {
		font-weight: $font-weight-bold;
	}
	.ui-datepicker-calendar {
		margin: 0;
		background-color: transparent;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		th {
			text-transform: uppercase;
			font-size: $default-value-size * 10;
			font-weight: $font-weight-semibold;
			letter-spacing: 1px;
			padding: 6px 10px;
			color: #a3a7b7;
			background: $white;
		}
		td {
			border: 0px solid #f6f7fb;
			padding: 0;
			background-color: $white;
			text-align: right;
			&:last-child {
				border-right: 0;
			}
			&.ui-datepicker-other-month .ui-state-default {
				color: #ccc;
			}
			&.ui-state-disabled .ui-state-default {
				color: #ccc !important;
			}
			a, span {
				transition: all .2s ease-in-out;
				padding: 5px;
				background-color: $white !important;
				color: $text-color;
				padding: 6px 10px;
				display: block;
				font-weight: $font-weight-normal;
				font-size: $default-value-size * 12;
				border: 0;
				border-radius: 3px;
			}
			a:hover {
				background-color: #f0f2f7;
				color: $text-color;
			}
		}
		.ui-datepicker-today a {
			background-color: #f8f9fa;
			color: $text-color;
		}
	}
}
.ui-datepicker-multi {
	.ui-datepicker-group {
		padding-right: 15px;
		width: auto;
		float: left;
		.ui-datepicker-title {
			margin: auto;
		}
		.ui-datepicker-prev::before {
			left: 10px;
		}
		.ui-datepicker-next::before {
			right: 10px;
		}
		table {
			margin: 0;
		}
	}
	.ui-datepicker-group-last {
		padding-right: 0;
	}
}
.ui-datepicker-inline {
	max-width: 270px;
	border-radius: 12px;
}
/*-------- Date Picker -------*/