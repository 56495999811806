/*-------- Pricing Tables -------*/
.pricing {
	border-radius: 20px;
	box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.1), 0 2px 9px 0 rgba(62, 57, 107, 0.1);
	.btn {
		color: $white!important;
		border-width: 5px !important;
		border-radius: 100px;
		font-size: $default-value-size * 16;
		font-weight: $font-weight-bold !important;
	}
	.price {
		font-weight: $font-weight-semibold2;
		font-size: $default-value-size * 50;
		color: #434258 !important;
	}
	.list-unstyled li {
		border-bottom: 1px solid rgba(232, 235, 243, 0.7);
		padding: 7px 0;
	}
	.card-category{
		padding: 10px 0;
		border-radius: 4px;
		background: $border-color;
		color: $white;
	}
}
.pricing-table {
	width: 100%;
	margin: 0 auto;
	@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
	border-radius: 0;
	&:hover {
		box-shadow: 0 0 30px rgba($text-color, 0.2);
		> {
			.panel-footer-landing {
				box-shadow: 0 0 30px rgba($text-color, 0.05) inset;
				@include trasition-in(transition, all, 0.3s, ease, webkit);
			}
			.panel> {
				.panel-body-landing {
					background: #eef2f9;
					@include trasition-in(transition, all, 0.3s, ease, webkit);
				}
				.panel-heading-landing-box {
					background: $white!important;
					color: $text-color !important;
					@include trasition-in(transition, all, 0.3s, ease, webkit);
				}
				.controle-header {
					background: #eef2f9 !important;
					@include trasition-in(transition, all, 0.3s, ease, webkit);
				}
				.panel-footer {
					background: #eef2f9 !important;
					@include trasition-in(transition, all, 0.3s, ease, webkit);
					text-align: center;
					>.btn {
						border: solid 1px $white!important;
						@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
					}
				}
			}
		}
	}
}
.btn-price:hover {
	background: $white!important;
	color: #eef2f9 !important;
	@include trasition-in(transition, all, 0.3s, ease, webkit);
}
.pricing-table {
	&:hover {
		>.panel>.controle-header>.panel-title-landing {
			color: $white!important;
			@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
			font-size: $default-value-size * 35;
		}
		>.panel>.panel-body-landing>.table>tbody>tr>td {
			font-weight: $font-weight-semibold;
			@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
		}
	}
	>.panel>.controle-header>.panel-title-landing {
		color: $black !important;
		font-size: $default-value-size * 35;
	}
}
.price .list-group-item {
	border-bottom: 1px solid rgba(250, 250, 250, 0.5);
}
.price {
	.panel-footer {
		border-bottom: 0;
		background-color: $white;
	}
	&.panel-color>.panel-body {
		background-color: $white;
	}
}
.pricingTable {
	padding: 25px 0;
	border: 1px solid $border-color;
	text-align: center;
	.price-value {
		font-size: $default-value-size * 50;
		font-weight: 800;
		color: #0d3b66;
		letter-spacing: 3px;
		margin-bottom: 30px;
	}
	.month {
		display: block;
		font-size: $default-value-size * 17;
		font-weight: $font-weight-normal;
		color: #0d3b66;
		letter-spacing: 2px;
		line-height: 10px;
		text-transform: uppercase;
	}
	.title {
		display: inline-block;
		padding: 15px 40px;
		margin: 0 0 20px 0;
		font-size: $default-value-size * 20;
		text-transform: capitalize;
		letter-spacing: 1px;
		position: relative;
		@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
		&:after, &:before {
			content: "";
			border-right: 26px solid rgba(233, 30, 99, 0.1);
			border-top: 26px solid transparent;
			border-bottom: 26px solid transparent;
			position: absolute;
			top: 0;
			left: -26px;
			@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
		}
		&:after {
			border-right: none;
			border-left: 26px solid rgba(233, 30, 99, 0.1);
			left: auto;
			right: -26px;
		}
	}
	.pricing-content {
		padding: 0;
		margin: 0 0 25px 0;
		list-style: none;
		li {
			font-size: $default-value-size * 17;
			line-height: 40px;
		}
	}
	.pricingTable-signup {
		display: inline-block;
		padding: 7px 30px;
		font-size: $default-value-size * 17;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		position: relative;
		@include trasition-in(transition, all, 0.3s, ease, webkit);
		&:after, &:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			@include trasition-in(transition, all, 0.3s, ease, webkit);
		}
		&:hover {
			&:before {
				background: rgba($white, 0.7);
				left: 60%;
				right: 60%;
				z-index: 1;
			}
			&:after {
				background: rgba($white, 0.7);
				top: 60%;
				bottom: 60%;
			}
		}
	}
}
.pricing1 {
	color: #1f252d;
	border-radius: 7px;
	.list-unstyled li {
		border-bottom: 1px solid $border-color;
		padding: 7px 0;
	}
	.card-category {
		background: rgba($white, 0.3);
		padding: 20px 0;
		color: $white;
	}
}
.pricingTable2 {
	text-align: center;
	@include trasition-in(transition, all, 0.4s, ease, moz webkit o);
	background: $white;
	border: 1px solid $border-color;
	border-radius: 5px;
	overflow: hidden;
}
.pricingTable2-header {
	padding: 25px 10px;
	background-color: $white;
}
.pricingTable2 .pricingTable2-header h3 {
	font-size: $default-value-size * 20;
	letter-spacing: 2px;
	color: $black;
	text-transform: capitalize;
	margin: 0px;
	font-weight: $font-weight-semibold2;
}
.pricingTable2-header span {
	display: none;
	font-size: $default-value-size * 12;
}
.pricing-plans {
	padding: 25px 0;
}
.price-value1 {
	font-size: $default-value-size * 25;
	line-height: 35px;
	color: $white;
	display: inline-block;
	position: relative;
	i {
		position: absolute;
		top: 3px;
		font-size: $default-value-size * 22;
	}
	span {
		font-size: $default-value-size * 40;
		color: $white;
		margin-left: 17px;
		font-weight: $font-weight-bold;
	}
}
.pricing-plans .month {
	color: $white;
	display: inline-block;
	font-size: $default-value-size * 15;
	margin-top: 15px;
	font-weight: 100;
}
.pricingContent2 ul {
	padding: 0 15px;
	margin-bottom: 0;
	list-style: none;
	li {
		padding: 25px 0 0 0;
		text-transform: capitalize;
		&:last-child {
			padding-bottom: 25px;
		}
	}
}
.price-value-content ul li {
	padding: 8px 0;
	text-transform: capitalize;
}
.pricingTable2 {
	.pricingTable2-sign-up {
		padding: 25px 0;
		border-top: 1px solid $border-color;
	}
	.btn-block {
		width: 50%;
		margin: 0 auto;
		color: $white;
		text-transform: uppercase;
		border-radius: 50px;
		@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
	}
	&.green .pricing-plans {
		background-color: #3eb750;
	}
	&.info .pricing-plans {
		background-color: #23bcb9;
	}
}

.panel-body-landing:hover>.table>tbody>tr>td {
	color: $white!important;
	@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
}
.panel-heading-landing {
	background: #f7f7f7 !important;
	padding: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border: solid 2px #eef2f9 !important;
	border-bottom: none !important;
	text-align: center;
	margin-top: 20px;
}
.panel-heading-landing-box {
	background: #eef2f9 !important;
	color: $white!important;
	font-size: $default-value-size * 16 !important;
	text-align: center;
	border-top: none !important;
}
.panel-title-landing {
	color: #eef2f9 !important;
	font-size: $default-value-size * 35;
	font-weight: $font-weight-bold;
}
.panel-body-landing {
	border: solid 2px #eef2f9 !important;
	border-top: none !important;
	border-bottom: none !important;
	text-align: center;
	background: $white;
}
.panel-footer-landing {
	border: solid 2px #eef2f9 !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	border-top: none !important;
	text-align: center;
	background: #f7f7f7;
}
.panel.price {
	@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
	>.panel-heading {
		@include trasition-in(transition, all, 0.3s, ease, moz webkit o);
	}
	h3 {
		margin-bottom: 0;
		padding: 20px 0;
	}
}
.panel-heading {
	padding: 10px 15px;
	background: $white;
}
.panel.price>.panel-heading {
	padding: 0;
	position: relative;
}
.panel.price {
	.list-group-item {
		&:last-child {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:first-child {
			border-top-right-radius: 0;
			border-top-left-radius: 0;
		}
	}
	margin-bottom: 1.5rem;
}
.panel-body {
	padding: 15px;
	.lead {
		strong {
			font-size: $default-value-size * 40;
			margin-bottom: 0;
			font-weight: $font-weight-normal;
		}
		font-size: $default-value-size * 20;
		margin-bottom: 0;
		padding: 10px 0;
	}
}
.panel-footer {
	padding: 10px 15px;
	background-color: #f7f7f7;
	border-top: 1px solid $border-color;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.panel.price .btn {
	border: 0;
}
.price-value {
	position: relative;
}
.price-icon {
	position: absolute;
	border: 1px solid rgba($white, 0.5);
	transform: rotate(45deg);
	width: 60px;
	height: 60px;
	padding: 17px 20px;
	color: $white;
	font-size: $default-value-size * 20;
	text-align: center;
	bottom: -30px;
	left: 0;
	right: 0;
	margin: 0 auto;
	border-radius: 5px;
	i {
		transform: rotate(-45deg);
	}
}
.pricingtables {
	.left-price {
		.panel, .panel-heading, .panel-body, .panel-footer {
			background: transparent;
			border: 0;
			box-shadow: none;
		}
		ul {
			box-shadow: 0 0px 2px #d5d7de;
		}
	}
	.sitelock ul {
		margin-top: 144px;
		border-radius: 7px 0 0 7px;
		overflow: hidden;
	}
	&.combination-pricing {
		.left-price ul li {
			&:first-child {
				border-top: 0;
			}
			color: $white;
			padding: 15px 20px;
			border-top: 1px solid $border-color;
			a {
				color: #7b7b7b;
			}
			&:nth-child(odd) {
				background: $white;
			}
			&:nth-child(even) {
				background: $body-color;
			}
		}
		.most-popular {
			border-width: 3px;
			box-shadow: 0 0 40px 0 rgba(234, 238, 249, 0.5);
			padding: 0;
			top: 0;
			border: 1px solid $border-color;
			z-index: 2;
			background: $white;
			border-left: 0;
			border-radius: 10px !important;
			overflow: hidden;
		}
		h4 {
			color: $text-color;
		}
		.most-popular {
			.yearprice, .twoyearprice {
				border-color: $white;
			}
			h4 {
				color: $text-color;
			}
			.panel-body span.per {
				background: transparent;
			}
			ul li {
				font-size: $default-value-size * 14;
				padding: 15px 0;
				border-top: 1px solid $border-color;
			}
		}
	}
}
.pricer-value {
	padding: 0px;
	text-align: center;
	h4 span {
		font-size: 2.5rem;
	}
}

.price-badge {
	width: 80px;
	height: 80px;
	position: absolute;
	top: -15px;
	right: -15px;
	border-radius: 50%;
	span {
		display: block;
		font-size: $default-value-size * 18;
		font-weight: bold;
		margin-top: 24px;
		margin-left: 13px;
	}
	del {
		margin: 20px 0 0 20px;
		font-weight: $font-weight-normal;
	}
}
.price-list-style li {
	border-bottom: 1px dashed $border-color;
	padding: 6px;
	&:last-child {
		border-bottom: 0;
	}
}
.price-special-title {
	width: 80%;
	margin: 0 auto;
	padding: 13px;
	border-radius: 0 0 40px 40px;
	color: $white;
	margin-bottom: 30px;
	font-size: $default-value-size * 15;
	text-transform: uppercase;
}

#price {
	border: 0;
	font-weight: $font-weight-semibold2;
	font-size: $default-value-size * 15;
	width: 150px !important;
}
/*-------- Pricing Tables -------*/
