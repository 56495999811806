/*-------- Custom Cards -------*/
.card-pay .tabs-menu {
	margin-bottom: 25px;
	border: 1px solid $border-color;
	border-radius: 4px;
	li {
		width: 33.3%;
		display: block;
		a {
			padding: .8rem 1rem;
			background: $white;
			display: block;
			text-align: center;
			border-right: 1px solid $border-color;
		}
		&:last-child a {
			border-right: 0;
		}
		a.active {
			/* color: $white; */
			border-radius: 0;
			text-align: center;
		}
	}
}
.card-over {
	position: relative;
	img {
		vertical-align: middle;
	}
	.content {
		position: absolute;
		bottom: 0;
		background: #374254;
		opacity: .9;
		color: $white;
		width: 100%;
		padding: 20px;
	}
}
.service-card-svg {
	svg {
		width: 45px;
		height: 45px;
		margin: 0 auto;
	}
	&.service-card-svg2 svg {
		width: 65px;
		height: 65px;
		margin: 0 auto;
	}
}
.bg-card-light {
	background: $border-color;
	text-align: center;
	transition: .4s;
	transform: scale(1);
	width: 100%;
}
.bg-card {
	background: $border-color;
	&:hover {
		.text-secondary-gradient {
			color: $white!important;
		}
		.cat-desc, .cat-img i {
			color: $white;
		}
	}
}
.card-footer.bg-primary {
	border-top: 1px solid rgba($white, 0.2);
}
.card-header.bg-primary {
	border-bottom: 1px solid rgba($white, 0.2);
}
.card-absolute:hover .edit-buttons-absolute {
	top: 12px;
	z-index: 1;
	transition: all 0.5s;
}
.card .ads-tabs .card {
	background: $white;
}
.card-aside-column1 {
	min-width: 8rem;
	width: 40%;
	-ms-flex: 0 0 40%;
	flex: 0 0 40%;
	background: no-repeat center/cover;
}
.card-img img {
	border-radius: 5px;
}
.card-img-overlay1 {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1.25rem;
	.card-trans {
		position: absolute;
		bottom: 20px;
		left: 15px;
		right: 15px;
		background: #0d102c;
		padding: 20px;
		border-radius: 7px;
	}
	.widgetbox {
		top: 0;
		left: 0;
		border-radius: 7px;
		background: $white;
		padding: 0 15px 0 15px;
	}
}
/*-------- Custom Cards -------*/