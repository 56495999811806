/*-------- absolute styles -------*/
%absolute-styles{
	left: 0;
	right: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.absolute-span {
	top: 40%;
	position: absolute;
	left: 0;
	right: 0;
	font-size: $default-value-size * 16;
}
label.error {
	position: absolute;
}
.img-absolute-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	width: 460px;
}
.absolute-title {
	@extend %absolute-styles; 
	z-index: 2;
	display: block;
	span {
		color: $white;
		font-weight: $font-weight-semibold;
		position: absolute;
		z-index: 2;
		top: 45%;
		left: 0;
		right: 0;
		display: block;
		margin: 0 auto;
		text-align: center;
		font-size: $default-value-size * 18;
		text-transform: uppercase;
		font-weight: $font-weight-semibold2;
	}
}
.date-icon{
	h6:first-child{
		margin-bottom: 10px;
		border-bottom: 1px solid $border-color;
		padding-bottom: 12px;
		position:relative;
		&:before{
			content: '';
			position: absolute;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background: #e5e4f0;
			bottom: -3px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}
}
.overlay {
	@extend %absolute-styles; 
	opacity: .7;
	z-index: 1;
}
.details-absolute {
	left: 0;
	right: 0;
	position: absolute;
	bottom: 0;
	width: 100%;
	background: rgba($black, 0.2);
	padding: 15px 0;
	z-index: 1;
	i {
		width: 2rem;
		height: 2rem;
		line-height: 2rem;
		background: rgba($white, 0.1);
		border-radius: 50%;
		display: inline-block;
		margin: 0 auto;
		text-align: center;
	}
}
.edit-buttons-absolute {
	top: -12px;
	left: 10px;
	position: absolute;
	z-index: 0;
	transition: all 0.5s;
}
.absolute-link2 {
	@extend %absolute-styles; 
	display: block;
	z-index: 1;
}
/*-------- absolute styles -------*/