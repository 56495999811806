//variables
@import "variables";

//bootstrap
@import "bootstrap/fonts";
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/mixins";
@import "bootstrap/images";
@import "bootstrap/headings";
@import "bootstrap/accordion";
@import "bootstrap/alerts";
@import "bootstrap/avatar";
@import "bootstrap/badges";
@import "bootstrap/breadcrumb";
@import "bootstrap/button-group";
@import "bootstrap/buttons";
@import "bootstrap/code";
@import "bootstrap/card";
@import "bootstrap/close";
@import "bootstrap/carousel";
@import "bootstrap/dropdown";
@import "bootstrap/button-dropdown";
@import "bootstrap/form-control";
@import "bootstrap/lists";
@import "bootstrap/loaders";
@import "bootstrap/media";
@import "bootstrap/modal";
@import "bootstrap/nav";
@import "bootstrap/jumbotron";
@import "bootstrap/navbar";
@import "bootstrap/navtabs";
@import "bootstrap/pagination";
@import "bootstrap/progress";
@import "bootstrap/table";
@import "bootstrap/tags";
@import "bootstrap/label";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/utilities";
@import "bootstrap/input-group";
@import "bootstrap/overflow";
@import "bootstrap/thumbnail";
@import "bootstrap/panel";
@import "bootstrap/back-to-top";

// layouts
@import "layouts/horizontal";
@import "layouts/mydash";
@import "layouts/global-loader";
@import "layouts/single-page";
@import "layouts/header";
@import "layouts/top-bar";
@import "layouts/footer";

//horizontal
@import "custom/vendors";
@import "custom/lib";
@import "custom/responsive";

.gallery-close-button-custom{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1050;
  color: #958a8a;
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.gallery-close-button i {
  font-size: 25px;
}

.item-card3-img1 {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.carousel-custom {
  position: relative;
}

.cover-images {
  width: 100%;
  height: auto;
}

.go-flex{
  display: flex;
  height: calc(100% - 3rem);
}

.test-flex{
  display: flex;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background: rgba(0, 0, 0, 1);
  background-image: none;
  width: 30px;
  height: 30px;
  font-size: 18px !important;
  color: $color-secondary-CC;
  line-height: 30px !important;
  border-radius: 50%;
}


.ribbon span.cc-big {
  left: -30px;
  top: 40px;
}

.cc-text-shadow {
  background: rgba($text-color, 0.45) !important;
}


.btn-love {
  color: #fff;
  background-color: $color-love;
  border-color: $color-love;
  box-shadow:0 0 12px 0 rgba($color-love, 0.35);
}
.btn-love:hover {
  color: $color-love;
  background-color: darken($color-love, 10%);
  border-color:  darken($color-love, 10%);
}


.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.width-auto {
  width: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ts06em {
  font-size: 0.6em !important;
}
.nowrap{
  white-space: nowrap;
}
.width-100p {
  width: 100% !important;
}
.width-101p {
  width: 101% !important;
}

.htmx-indicator{
  display:none;
  opacity:0;
  transition: opacity 300ms ease-in;
}
.htmx-request-hide.htmx-request{
  //display:none;
  opacity:0;
  transition: opacity 300ms ease-in;
}
.htmx-request-hide {
  opacity:1;
  transition: opacity 300ms ease-in;
}

.htmx-indicator.htmx-request{
  display:block;
  opacity:1
}

.bg-facebook-messenger {
  background-color: #0084ff !important;
}
.text-facebook-messenger {
  color: #0084ff !important;
}

a.text-facebook-messenger {
  color: #0084ff !important;
}

.text-facebook-messenger-50 {
  color: rgba($color-facebook-messenger, 0.5) !important;
}

a.bg-facebook-messenger {
  &:focus, &:hover {
    background-color: #0084FF !important;
  }
}
button.bg-facebook-messenger {
  &:focus, &:hover {
    background-color: #00C6FF  !important;
  }
}

.card-body.card-body-slim {
  padding: 1.5rem 0.25rem !important;
}