/*-------- Global Loader -------*/
#global-loader {
	position: fixed;
	z-index: 50000;
	background: $white;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}
.loader-svg-img {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 33%;
	margin: 0 auto;
	width: 300px;
	height: 300px;
	line-height: 300px;
	border-radius: 50%;
	background: url(../images/other/loader.svg);
	img{
		width:auto;
	}
}
.loader-svg {
	left: 0;
	right: 0;
	text-align: center;
	top: 33%;
	margin: 0 auto;
	width: 300px;
	height: 300px;
	line-height: 300px;
	border-radius: 50%;
	-webkit-mask-image: url(../images/other/loader.svg);
	mask-image: url(../images/other/loader.svg);
	background: $color-primary;
	img{
		width:auto;
	}
}


.flight-icon {
	position: absolute;
	top: 37%;
	color: $white;
	transform: rotate(45deg);
	left: 0;
	right: 0;
	z-index: 9999;
	animation: fa-spin 10s infinite linear;
	width: 78px;
	height: 78px;
	display: flex;
	margin: 0 auto;
	line-height: 80px;
	font-size: $default-value-size * 18;
}

.flight-icon-dark {
	position: absolute;
	top: 37%;
	color: $text-color;
	transform: rotate(45deg);
	left: 0;
	right: 0;
	z-index: 9999;
	animation: fa-spin 10s infinite linear;
	width: 78px;
	height: 78px;
	display: flex;
	margin: 0 auto;
	line-height: 80px;
	font-size: $default-value-size * 18;
}
/*-------- Global Loader -------*/