/*-------- Button Groups -------*/

.btn-group {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group-vertical {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
	>.btn {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
	}
}
.btn-group>.btn {
	position: relative;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
}
.btn-group-vertical>.btn:hover, .btn-group>.btn:hover {
	z-index: 1;
}
.btn-group-vertical>.btn {
	&.active, &:active, &:focus {
		z-index: 1;
	}
}
.btn-group {
	>.btn {
		&.active, &:active, &:focus {
			z-index: 1;
		}
	}
	.btn+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
	.btn-group+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
}
.btn-group-vertical {
	.btn+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
	.btn-group+ {
		.btn, .btn-group {
			margin-left: -1px;
		}
	}
}
.btn-toolbar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
	.input-group {
		width: auto;
	}
}
.btn-group> {
	.btn:first-child {
		margin-left: 0;
	}
	.btn-group:not(:last-child)>.btn, .btn:not(:last-child):not(.dropdown-toggle) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.btn-group:not(:first-child)>.btn, .btn:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
.dropdown-toggle-split {
	padding-right: .5625rem;
	padding-left: .5625rem;
	&::after {
		margin-left: 0;
	}
}
.dropright .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}
.btn-group-sm>.btn+.dropdown-toggle-split, .btn-sm+.dropdown-toggle-split {
	padding-right: .375rem;
	padding-left: .375rem;
}
.btn-group-lg>.btn+.dropdown-toggle-split, .btn-lg+.dropdown-toggle-split {
	padding-right: .75rem;
	padding-left: .75rem;
}
.btn-group-vertical {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	.btn, .btn-group {
		width: 100%;
	}
	> {
		.btn+ {
			.btn, .btn-group {
				margin-top: -1px;
				margin-left: 0;
			}
		}
		.btn-group {
			+ {
				.btn, .btn-group {
					margin-top: -1px;
					margin-left: 0;
				}
			}
			&:not(:last-child)>.btn {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		.btn:not(:last-child):not(.dropdown-toggle) {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
		.btn-group:not(:first-child)>.btn, .btn:not(:first-child) {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}
.btn-group-toggle> {
	.btn, .btn-group>.btn {
		margin-bottom: 0;
	}
	.btn input {
		&[type=checkbox], &[type=radio] {
			position: absolute;
			clip: inherit;
			pointer-events: none;
		}
	}
	.btn-group>.btn input {
		&[type=checkbox], &[type=radio] {
			position: absolute;
			clip: inherit;
			pointer-events: none;
		}
	}
}
.btn-group-toolbar{
	.btn-group{
		.btn{
			border-radius:0px;
		}
		&:first-child{
			.btn:first-child{
				border-radius:5px 0 0 5px;;
			}
		}
		&:last-child{
			.btn:last-child{
				border-radius: 0 5px 5px 0;
			}
		}
	}
}

.btn-group-lg>.btn, .btn.btn-lg {
	padding: $space-sm $space-md;
	font-size: 1.125rem;
	line-height: 28px;
	border-radius: 5px;
	height: 46px;
	font-weight: $font-weight-semibold !important;
}
.btn-group-sm>.btn, .btn.btn-sm {
	padding: $space-xs $space-sm;
	font-size: .875rem;
	height: 30px;
	line-height: 22px !important;
	border-radius: 3px;
}
/*-------- Button Groups -------*/