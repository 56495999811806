/*------ IE -------*/
*::-ms-backdrop, .sptb .item-card9-img {
	height: auto;
}
*::-ms-backdrop, .sptb .ads-tabs .item-card9-img{
	height: 211px ;
}
*::-ms-backdrop, .sptb .userprof-tab .item-card9-img{
	height: 232px ;
}
*::-ms-backdrop, .sptb.pb .item-user .profile-pic img{
	height: 200px ;
}
*::-ms-backdrop, .sptb.pb  #tab-6 .item-card9-img{
	height: 212px ;
}
*::-ms-backdrop, .map-content-width #tab-12 .item-card9-img{
	height: auto;
}
*::-ms-backdrop, .map-content-width #tab-11 .item-card9-imgs{
	height: auto;
}
*::-ms-backdrop, .map-content-width #tab-11 .item-card9 .leading-tight{
	font-size: $default-value-size * 13;
}
*::-ms-backdrop, .map-content-width #tab-11 .item-card9 a{
	font-size: $default-value-size * 13;
}
*::-ms-backdrop, .map-content-width #tab-11 .item-card9 h4{
	font-size:15px;
}
*::-ms-backdrop, .item-card8-desc{
	text-align:left;
}
*::-ms-backdrop, .place-tour-card .place-tour small{
	position:static !important;
}
*::-ms-backdrop, .ieh-100{
	height:100%;
}
*::-ms-backdrop, .carousel-item img{
	height:100%;
}
*::-ms-backdrop, .carousel#image-slider{
	height:100%;
}
*::-ms-backdrop, .spcl-ie-img{
	height:70px !important;
	width:150px !important;
}
*::-ms-backdrop, .row-deck > .col, .row-deck > [class*=col-]{
	display:block;
}
*::-ms-backdrop, .usertab-list{
	width:100%;
}
*::-ms-backdrop, .usertab-list li{
	float:inherit;
}
*::-ms-backdrop, .app-sidebar .side-menu__item.active:before{
	display:none;
}
*::-ms-backdrop, .slide-menu li a.slide-item.active:after{
	display:none;
}
*::-ms-backdrop, .timeline-tour .tour-before {
    max-width: 100%;
    flex-shrink: 0;
}
*::-ms-backdrop, .card{
	 align-items: stretch;
	 max-width:100%;
}
.custom-control-label, ::-ms-backdrop {
	position: static;
}
.media-body, .media-left, .media-right, ::-ms-backdrop {
	display: list-item;
	vertical-align: top;
}
.media-left, .media-right, .table-responsive .media-body, ::-ms-backdrop {
	display: table-cell;
}
.iteam-all-icon, ::-ms-backdrop {
	height: 100%;
}
.position-absolute, ::-ms-backdrop {
	position: absolute !important;
}
/*------ IE -------*/
