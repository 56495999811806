/*-------- Tags -------*/
.tag {
	font-size: .75rem;
	color: $text-color;
	background-color: $border-color;
	border-radius:  $default-value-size * 5;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: $font-weight-normal;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background-color: rgba(110, 118, 135, 0.2);
		color: inherit;
	}
}
.tag-addon {
	display: inline-block;
	padding: 0 .5rem;
	color: inherit;
	text-decoration: none;
	background: rgba($text-color, 0.06);
	margin: 0 -.5rem 0 .5rem;
	text-align: center;
	min-width: 1.5rem;
	&:last-child {
		border-top-right-radius: $default-value-size * 3 ;
		border-bottom-right-radius:  $default-value-size * 3 ;
	}
	i {
		vertical-align: middle;
		margin: 0 -.25rem;
	}
}
a.tag-addon {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background: rgba($text-color, 0.16);
		color: inherit;
	}
}
.tag-avatar {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 3px 0 0 3px;
	margin: 0 .5rem 0 -.5rem;
}
.tag-rounded {
	border-radius:  $default-value-size * 50 ;
	.tag-avatar {
		border-radius:  $default-value-size * 50 ;
	}
}
.tags {
	margin-bottom: -.5rem;
	font-size: 0;
	>.tag {
		margin-bottom: .5rem;
		&:not(:last-child) {
			margin-right: .5rem;
		}
	}
}

/*-------- Tag colors  -------*/
@each $colors, $value in $colors {
	@include tag-variant(".tag-#{$colors}", $value);
}
/*-------- Tags -------*/
